import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import styled, { withTheme } from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import Cart from '@/components/molecules/cart'
import { ITheme } from '../../../../theme'
import DesktopHeaderButton from '@/components/molecules/desktopHeaderButton'
import { Routes } from '@/core/routes'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import LoginButton from '@/components/molecules/loginButton'
import SlideMenuAdditionalInfo from '@/components/organisms/slideMenuAdditionalInfo'
import SwiperMenuAdd from '@/components/organisms/swiperMenuAdd'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import { WishlistIconDefault } from '@/components/atoms/icons/WishlistIconDefault'
import ProductSearch from '@/components/molecules/productSearch'
import { useLuigiTracker } from '@/providers/luigiTrackerProvider'
import ProductSearchPopUpWrapper from '@/components/molecules/productSearch/productSearchPopUpWrapper'
import { useAsset } from '@/providers/assetsProvider'
import { ChristmasGiftBoxIconDefault } from '@/components/atoms/icons/ChristmasGiftBoxIconDefault'

type DesktopToolbarProps = {
  theme: ITheme
  wishlistProductCount: number
}

const DesktopToolbar: FC<DesktopToolbarProps> = ({
  theme,
  wishlistProductCount,
  ...rest
}) => {
  const { t } = useTranslation('common')
  const { getFocusType } = useCurrentPage()
  const { show, showAdditionalMenu, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()
  const focusType = getFocusType()
  const display = focusType === FocusMode.Basic ? 'none' : 'flex'
  const { isLuigiSearchSupported } = useLuigiTracker()
  const { isChristmasPromoTime, isShutDownCountry } = useAsset()

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height="100px"
      {...rest}
    >
      <Flex flexDirection="row" alignItems="center">
        <ToolbarLinkLogo mr={70} />
        {isLuigiSearchSupported && !isShutDownCountry ? (
          <ProductSearch display={display} />
        ) : !isLuigiSearchSupported && !isShutDownCountry ? (
          <ProductSearchPopUpWrapper />
        ) : null}
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <LoginButton px={10} mr={focusType === FocusMode.Basic && '30px'} />
        {!isShutDownCountry && (
          <StyledHeaderButton
            title={t('Toolbar.Buttons.wishlist')}
            hrefLink={Routes.wishlist}
            notifyCount={wishlistProductCount}
            IconElement={
              isChristmasPromoTime ? (
                <ChristmasGiftBoxIconDefault
                  color={theme.colors.onBackground}
                />
              ) : (
                <WishlistIconDefault color={theme.colors.onBackground} />
              )
            }
            mr="30px"
            data-cy="headerWishlistButton"
            display={display}
          />
        )}
        {!isShutDownCountry && (
          <Cart data-cy="headerCart" isShowingOnDefaultToolbar={true} />
        )}
        {show ? <SlideMenuAdditionalInfo /> : null}
        {showAdditionalMenu || showAdditionalMenuOnHP ? (
          <SwiperMenuAdd />
        ) : null}
      </Flex>
    </Flex>
  )
}

export default withTheme(DesktopToolbar)

const StyledHeaderButton = styled(DesktopHeaderButton)`
  padding-right: 10px;
  padding-left: 10px;
  border: 0;
`
