import { Translate } from 'next-translate'
export const autoCompleteInit = (
  trackerId: string,
  translate: Translate
): string => {
  return ` 
    function LBInitAutocomplete() { 
    if(typeof AutoComplete !== 'undefined') {
    AutoComplete(
      {
        Layout: 'grid',
        ShowBranding: false,
        GridPosition: 'left', // left or righ (placement for main element)
        Align: 'input',
        TrackerId: '${trackerId}',
        FollowSuggestedUrl: true,
        Types: [
          { type: 'query', placement: 'main', size: 6, name: '${translate(
            'Luigi.Types.searchTerm'
          )}' },
          { type: 'item', placement: 'main', name: '${translate(
            'Luigi.Types.itemTitle'
          )}' }, 
          { type: 'category', placement: 'others', size: 9,  name: '${translate(
            'Luigi.Types.categoryTitle'
          )}' },
          { type: 'brand', placement: 'others', size: 9, name: '${translate(
            'Luigi.Types.brandTitle'
          )}' }
        ],
        ShowAllTitle: '${translate('Luigi.showAllTitle')}',
        FormatForDisplay: function (result) {
      if (result && 
          result.attributes &&
          result.attributes.disambiguated_hierarchy &&
          result.attributes.disambiguated_hierarchy[0]) { result.attributes.title = result.attributes.disambiguated_hierarchy[0] + ' > ' + result.attributes.title; } 
      return result;
        },
        AfterRender: function (query, results) { },
        BeforeOpen: function () { },
        AfterClose: function () { },
        
      },
      '.fulltext-autocomplete'
    )
    }
  }`
}
